const handleInputNumber = (config) => {
  const { value, max, min } = config
  let tempValue = parseFloat(value)
  if (tempValue > min && tempValue <= max) tempValue = tempValue
  else if (tempValue > max) tempValue = max
  else tempValue = min
  return tempValue
}

export default handleInputNumber
