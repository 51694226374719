import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import MedicinesCard from "../Cart/MedicinesCard"
import DocumentsCard from "../Cart/DocumentsCard"
import OrderTotalSection from "../Cart/OrderTotalSection"
import CouponDiscountSection from "../Cart/CouponDiscountSection"
import OrderSubtotalSection from "../Cart/OrderSubtotalSection"
import Button from "../Elements/Button"

import { AppContext } from "../../context/AppContext"
import { getCouponDiscountAmount } from "./services/coupon"
import { generateDeliveryFee } from "./services/computations"
import styles from "./utils/epharmacy.module.scss"

const Cart = ({ open, hidden }) => {
  const { state, dispatch } = useContext(AppContext)
  // const [isAccumulatedPriceValid, setIsAccumulatedPriceValid] = useState(0)
  const { epharmacy, documents } = state
  const { medicines, coupon, hasPartnerRequest, deliveryAddress } = epharmacy
  const hasNoMeds = !(medicines.length + !!hasPartnerRequest)
  const [isLoading, setIsLoading] = useState(false)
  const buttonText = hasNoMeds
    ? "Your medicine list cannot be empty."
    : "Checkout"

  // useEffect(() => {
  //   const totalPrice = parseFloat(accumulatePrice(medicines))
  //   const MINIMUM_BASKET_VALUE = 300.0
  //   setIsAccumulatedPriceValid(totalPrice < MINIMUM_BASKET_VALUE)
  // }, [medicines])

  const handleRedirectToDeliveryDetails = () => {
    setIsLoading(true)
    navigate("/epharmacy/checkout")
  }

  let prescriptionDocuments = documents.filter(
    (doc) => !doc.type?.includes("FRONT") && !doc.type?.includes("BACK")
  )

  return (
    <div className="mb-3">
      {!hidden?.documents && (
        <DocumentsCard
          title={`Documents Uploaded (${prescriptionDocuments.length})`}
          open={open?.documentsCard}
        />
      )}
      {!hidden?.medicines && (
        <div>
          <MedicinesCard open={open?.medicinesCard} />
          {medicines.length > 0 && <OrderTotalSection medicines={medicines} />}
          {coupon && medicines.length > 0 && (
            <CouponDiscountSection
              couponDiscount={getCouponDiscountAmount(
                coupon,
                medicines,
                generateDeliveryFee(epharmacy.addressType)
              )}
              medicines={medicines}
              coupon={coupon && coupon.code}
              addressType={epharmacy.addressType}
            />
          )}
          {medicines.length > 0 && (
            <OrderSubtotalSection
              medicines={medicines}
              couponDiscount={getCouponDiscountAmount(
                coupon,
                medicines,
                generateDeliveryFee(epharmacy.addressType)
              )}
              coupon={coupon && coupon.code}
              addressType={epharmacy.addressType}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Cart
