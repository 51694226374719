export const generatePrice = (medicine) => {
  if (!medicine.price_list) return (0).toFixed(2)
  let vatex = medicine.price_list.vatex_unit_price || 0
  let vat = medicine.price_list.vat || 0
  return parseFloat(vatex + vat).toFixed(2)
}

export const accumulateVat = (medicines) => {
  let total = 0
  medicines.forEach((medicine) => {
    total += medicine.price_list.vat * medicine.qty
  })
  return total
}

export const accumulatePrice = (medicines) => {
  let total = 0
  medicines.forEach((medicine) => {
    total += medicine.qty * parseFloat(generatePrice(medicine))
  })
  return total
}

export const generateDeliveryFee = (addressType, province, beneficiaryType) => {
  let DELIVERY_FEE = 0
  const MM_DELIVERY_FEE = 200.0
  const PROVINCIAL_DELIVERY_FEE = 500.0
  if (addressType) {
    switch (addressType) {
      case "Office":
        DELIVERY_FEE = 0
        break
      case "Home":
        if (province && province !== "Metro Manila")
          DELIVERY_FEE = PROVINCIAL_DELIVERY_FEE
        else DELIVERY_FEE = MM_DELIVERY_FEE
        break
    }
    if (beneficiaryType) {
      switch (beneficiaryType) {
        case "PRIMARY":
          DELIVERY_FEE = 0
          break
        case "SECONDARY":
          DELIVERY_FEE = DELIVERY_FEE * 0.5
          break
      }
    }
    return DELIVERY_FEE
  }
}

export const checkIfHasRxRequired = (medicines) => {
  if (medicines.length === 0) return true
  return medicines.some((medicine) => medicine.rxRequired === true)
}

export const initializeMaxPaymentValue = (subTotal) => {
  const value = parseFloat(subTotal)
  return Math.ceil(value / 1000) * 1000
}
