import api from "../../../services/api"
import moment from "moment"
import specialCoupons from "../utils/specialCoupons.json"

function roundNumber(num, scale) {
  if(!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = ""
    if(+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

function isProductIncluded(item, include, exclude) {
  if (!include) return false
  var isIncluded = false
  const findCategory = (singleCategory) => singleCategory === exclude[i].title
  for (var i = 0; i < include.length; i++) {
    switch (include[i].type) {
      case "all":
        isIncluded = true
        break
      case "classification":
        if (item.classification === include[i].title) isIncluded = true
        break
      case "category":
        if (item.category.find(findCategory)) isIncluded = true
        break
      case "product":
        if (item.productTitle === include[i].title) isIncluded = true
        break
      case "rxLevel":
        if (include[i].value.indexOf(item.rxLevel) >= 0) isIncluded = true
        break
      case "rxRequired":
        if (item.rxRequired === include[i].value) isIncluded = true
        break
      default:
        isIncluded = false
        break
    }
  }
  if (exclude) {
    for (i = 0; i < exclude.length; i++) {
      switch (exclude[i].type) {
        case "all":
          isIncluded = false
          break
        case "classification":
          if (item.classification === exclude[i].title) isIncluded = false
          break
        case "category":
          if (item.category.find(findCategory)) isIncluded = false
          break
        case "product":
          if (item.id === exclude[i].productId) isIncluded = false
          break
        case "rxRequired":
          if (item.rxRequired === exclude[i].value) isIncluded = false
          break
        default:
          isIncluded = false
          break
      }
    }
  }

  return isIncluded
}

export const getCoupon = (couponCode, callback, errorCallback) => {
  couponCode = couponCode.toUpperCase()
  api
    .get(`coupons/${couponCode}/`)
    .then(async (response) => {
      if (response.data.expiration) {
        const isCouponExpired =
          moment().diff(response.data.expiration, "minutes") > 0
        if (isCouponExpired)
          // added response to make sure that the error message of axios is the same as the custom error message
          throw { message: "This coupon has expired", response: {} }
      }
      if (response.data.totalUseLimit === 0)
        throw { message: "This coupon has been used", response: {} }

      if (callback) callback(response.data)
    })
    .catch((error) => {
      const { status } = error.response
      if (errorCallback) errorCallback(status === 404 ? "" : error.message)
    })
}

export const getCouponDiscountAmount = (coupon, medicines, deliveryFee) => {
  if (coupon?.error) return "0.00"
  if (!coupon) return "0.00"
  if (specialCoupons.includes(coupon.code)) {
    const amount = medicines.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        parseFloat(currentValue.price_list.vatex_unit_price) *
          parseInt(currentValue.qty) *
          0.2
      )
    }, 0)
    return parseFloat(amount).toFixed(2)
  } else {
    const { productInclusions, productExclusions } = coupon
    var sum = 0
    var discountAmount = 0
    if (coupon.includeConvenienceFee) sum += parseFloat(deliveryFee)
    medicines.forEach((medicine) => {
      if (isProductIncluded(medicine, productInclusions, productExclusions)) {
        sum +=
          (parseFloat(
            parseFloat(medicine.price_list.vatex_unit_price).toFixed(2)
          ) +
            parseFloat(medicine.price_list.vat)) *
          parseFloat(medicine.qty)
      }
    })

    if (!!coupon.percentOff) {
      sum = (sum * parseFloat(coupon.percentOff)) / 100
    }
    if (!!coupon.maxAmount && sum >= parseFloat(coupon.maxAmount)) {
      discountAmount = parseFloat(coupon.maxAmount)
    } else discountAmount = sum
    return parseFloat(discountAmount).toFixed(2)
  }
}

export const checkIfInternalCoupon = (coupon) => {
  const couponType = coupon.slice(0, 2)
  if (couponType === "AC" || couponType === "RM") {
    return true
  } else return false
}
