import React, { useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import MedicinesCardRow from "./MedicinesCard/MedicinesCardRow"
import CollapsibleCard from "../Elements/CollapsibleCard"
import Button from "../Elements/Button"

import { AppContext } from "../../context/AppContext"
import handleInputNumber from "./utils/handleInputNumber"
import styles from "./utils/cart.module.scss"

const MedicinesCard = () => {
  const { dispatch, state } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy

  const handleMedicineQtyChange = async (event, index) => {
    const medicineList = [...medicines]

    if (medicineList[index].molecule) {
      let { value } = event.target
      if (value.includes("e") || value.includes("-")) return
      value = handleInputNumber({ value, min: 1, max: 200 })
      medicineList[index].qty = value
      await dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
      await dispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...state,
          epharmacy: { ...state.epharmacy, medicines: [...medicineList] },
        },
      })
    }
  }

  const handleDeleteMedicine = async (index) => {
    const medicineList = [...medicines]
    medicineList.splice(index, 1)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    // await dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
  }

  const handleRedirectToSearch = () => {
    dispatch(
      {
        type: "HIDE_MODAL",
      },
      navigate("/epharmacy/order")
    )
  }

  return (
    <CollapsibleCard
      title={`Medicines Ordered (${medicines.length})`}
      color="white"
      errorMessage={
        medicines.length === 0 &&
        !epharmacy?.hasPartnerRequest && {
          message: "You have no medicines in your list.<br/>",
          onClick: handleRedirectToSearch,
        }
      }
      isOpen={true}
    >
      <div>
        <ol className={classNames(styles["cart"], "has-text-left")}>
          {medicines.map((medicine, index) => (
            <MedicinesCardRow
              key={index}
              medicine={medicine}
              index={index}
              handleDeleteMedicine={handleDeleteMedicine}
              handleMedicineQtyChange={handleMedicineQtyChange}
            />
          ))}
        </ol>
        {/* <p className="has-text-left">
          <Button
            className="is-text has-text-primary"
            onClick={handleRedirectToSearch}
          >
            + add more medicines
          </Button>
        </p> */}
      </div>
    </CollapsibleCard>
  )
}

export default MedicinesCard
