import React, { Fragment } from "react"
import classNames from "classnames"
import { accumulateVat } from "../Epharmacy/services/computations"
import specialCoupons from "../Epharmacy/utils/specialCoupons.json"
import styles from "./utils/cart.module.scss"

const CouponDiscountSection = ({ couponDiscount, medicines, coupon }) => {
  const finalCouponDiscount =
    parseFloat(couponDiscount) +
    (specialCoupons.includes(coupon)
      ? parseFloat(accumulateVat(medicines).toFixed(2))
      : 0)
  const percentageOff =
    coupon.includes("PRIMARY") ? "100% off":"50% off"

  return (
    <Fragment>
      <hr
        className={classNames("has-background-light", styles["row__divider"])}
      />
      <div className="columns is-size-6 is-mobile">
        <div className="column has-text-right">Company Coverage:</div>
        <div className="column pb-0 is-5 has-text-weight-bold has-text-right">
          <span className="mr-1 has-text-danger">
            {/* - Php {finalCouponDiscount.toFixed(2)} */}
            {percentageOff}
          </span>
        </div>
      </div>
    </Fragment>
  )
}

export default CouponDiscountSection
