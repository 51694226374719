import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./utils/upload.module.scss"
import classNames from "classnames"

const FilePreview = ({ file, isPdf }) => {
  const data = useStaticQuery(graphql`
    {
      pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed
  return (
    <Fragment>
      {isPdf ? (
        <Img fixed={pdfThumbnail} />
      ) : (
        <figure>
          <img src={file.path} alt="attachment" />
        </figure>
      )}
    </Fragment>
  )
}

export default FilePreview
