import React, { Fragment,useContext,useEffect } from "react"
import classNames from "classnames"
import {
  accumulatePrice,
} from "../Epharmacy/services/computations"
import styles from "./utils/cart.module.scss"


const OrderSubtotalSection = ({ medicines}) => {
  let total = accumulatePrice(medicines).toFixed(2)

  return (
  <Fragment>
    <hr
      className={classNames("has-background-light", styles["row__divider"])}
    />

    <div className="columns is-size-6 is-mobile">
      <div className="column has-text-right">Total Order Value:</div>
      <div className="column is-5 has-text-weight-bold has-text-right">
        <span className="mr-1">
          Php{" "}
          {total}
        </span>
      </div>
    </div>
  </Fragment>
)}

export default OrderSubtotalSection
