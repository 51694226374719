import React from "react"
import classNames from "classnames"

import RxIndicator from "../../Elements/RxIndicator"
import { generatePrice } from "../../Epharmacy/services/computations"
import styles from "../utils/cart.module.scss"

const MedicinesCardRow = ({
  medicine,
  handleDeleteMedicine,
  handleMedicineQtyChange,
  index,
}) => (
  <li>
    <div className="columns is-mobile">
      <div className={classNames("column", styles["column"])}>
        <button
          onClick={() => handleDeleteMedicine(index)}
          className={classNames([
            styles["closeButton"],
            "is-pulled-right ml-1",
          ])}
        >
          <a className="delete" />
        </button>
        <div className="is-flex has-text-weight-bold">
          <span className="has-text-primary is-size-6">
            {medicine.productTitle}{" "}
          </span>
          {medicine.rxRequired && <RxIndicator />}
        </div>
      </div>
    </div>
    <div className="columns has-text-weight-bold">
      <div className={classNames("column", styles["column"])}>
        Unit Price: Php {generatePrice(medicine)}
      </div>
    </div>
    <div className="columns is-mobile">
      <div className={classNames("column", styles["column"])}>
        <span className={classNames([styles["quantity"]])}>
          <div className="field has-addons">
            <p className="control">
              <a className="button is-static">Qty:</a>
            </p>
            <p className="control">
              <input
                onChange={(event) => handleMedicineQtyChange(event, index)}
                onKeyPress={(event) => {
                  if (isNaN(event.key)) event.preventDefault()
                }}
                className="input"
                type="number"
                pattern="[0-9]"
                value={medicine.qty}
              />
            </p>
          </div>
        </span>
      </div>
      <div className={classNames("column is-narrow", styles["column"])}>
        <div className="is-size-6 tag">
          Php {(generatePrice(medicine) * medicine.qty).toFixed(2)}
        </div>
      </div>
    </div>
  </li>
)

export default MedicinesCardRow
